
		export default {
			staticResourcesBucketUrl: 'https://s3-eu-central-1.amazonaws.com/wwood-staticresourcess3bucket-yq3boz9m79h4',
			documentStoreBucketUrl: 'https://s3-eu-central-1.amazonaws.com/wwood-documentstores3bucket-1evc43zrc81dx',
			apiGatewayBaseUrl: 'https://5667spieql.execute-api.eu-central-1.amazonaws.com/Prod',
			realtimeTrackingWebSocketUri: 'wss://batvns6kx2.execute-api.eu-central-1.amazonaws.com/Prod',
			languageCodes: 'hu,en'.split(','),
			onesignalAuthToken: 'Basic MGMxZjQ0ZjYtZWE1My00ODBkLTljYjQtZGY4MWU0ZGJjMzI1',
			onesignalAppId: '04fbfac5-0f04-4b39-81f8-c139d3edfcf0',
			statTypes: '{"data":["heatmap","real-time","active-users-monthly","active-users-daily","total-users","total-zone-visits-monthly","total-zone-visits","dwell-time-by-dow","asked-per-answered-quiz","right-per-wrong-quiz","rating-value","push-sent","push-opened","treasure_hunt-gamers","asked-per-answered-treasure_hunt"]}'
		}
	
